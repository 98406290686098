/*globals $ */

class EventFilter {
  constructor() {
    // this.$container = $('.EventGrid:not(.EventGrid--archive)');
    this.$container = $('.EventGrid');
    this.$items = [];

    this.$container.find('.EventGrid__col').each((i, e) => {
      this.$items.push($(e));
    });
    // console.log(this.$items);

    this.selector = $('#select-event-filter');
    this.selector.change(() => {
      // year,month,week
      // console.log(this.selector.val());
      this.filter(this.selector.val());
    });

    $('#select-event-category').change(() => {
      // year,month,week
      // console.log(this.selector.val());
      console.log($('#select-event-category').val());
      // this.filter($('#select-event-category').val());
      if($('#select-event-category').val() != '') {
        location.href = $('#select-event-category').val();
      } else {
        location.href = '/event/';
      }
    });
  }

  filter(val) {
    let currentDate = new Date();
    let currentWeekMinDate = new Date();
    let currentWeekMaxDate = new Date();
    if(currentDate.getDay() == 0) {
      currentWeekMinDate.setDate(currentWeekMinDate.getDate() - 6);
    } else {
      currentWeekMinDate.setDate(currentWeekMinDate.getDate() - (currentDate.getDay() - 1));
      currentWeekMaxDate.setDate(currentWeekMaxDate.getDate() + (6 - (currentDate.getDay() - 1)));
    }
    currentWeekMinDate.setHours(0, 0, 0, 0);
    currentWeekMaxDate.setHours(0, 0, 0, 0);
    // console.log('chkMonth', currentWeekMinDate.getMonth());
    // console.log('min', String(currentWeekMinDate.getMonth() + 1) + '月' + currentWeekMinDate.getDate() + '日');
    // console.log('max', String(currentWeekMaxDate.getMonth() + 1) + '月' + currentWeekMaxDate.getDate() + '日');

    // let tmpItems = [];
    let fadeinCount = 0;

    if(val == 'year') {
      for(let i = 0; i < this.$items.length; i++) {
        let itemDate = this.$items[i].attr('data-date');
        if(String(currentDate.getFullYear()) == itemDate.substr(0, 4)) {
          // tmpItems.push(this.$items[i].attr('data-id'));
          this.$items[i].stop(false).delay(fadeinCount * 100).css({'opacity':0, 'display':'block'}).animate({'opacity':1}, 500, 'easeInOutSine');
          fadeinCount++;
        } else {
          this.$items[i].stop(false).css('display', 'none');
        }
      }
    } else if(val == 'month') {
      for(let i = 0; i < this.$items.length; i++) {
        let itemDate = this.$items[i].attr('data-date');
        if(
          String(currentDate.getFullYear()) == itemDate.substr(0, 4)
          && currentDate.getMonth() + 1 == Number(itemDate.split('/')[1])
        ) {
          // tmpItems.push(this.$items[i].attr('data-id'));
          this.$items[i].stop(false).delay(fadeinCount * 100).css({'opacity':0, 'display':'block'}).animate({'opacity':1}, 500, 'easeInOutSine');
          fadeinCount++;
        } else {
          this.$items[i].stop(false).css('display', 'none');
        }
      }
    } else if(val == 'week') {
      for(let i = 0; i < this.$items.length; i++) {
        let itemDate = new Date(this.$items[i].attr('data-date'));
        if(
          itemDate.getTime() >= currentWeekMinDate.getTime()
          && itemDate.getTime() <= currentWeekMaxDate.getTime()
        ) {
          // tmpItems.push(this.$items[i].attr('data-id'));
          this.$items[i].stop(false).delay(fadeinCount * 100).css({'opacity':0, 'display':'block'}).animate({'opacity':1}, 500, 'easeInOutSine');
          fadeinCount++;
        } else {
          this.$items[i].stop(false).css('display', 'none');
        }
      }
    } else if(val == '') {
      for(let i = 0; i < this.$items.length; i++) {
        this.$items[i].stop(false).delay(fadeinCount * 100).css({'opacity':0, 'display':'block'}).animate({'opacity':1}, 500, 'easeInOutSine');
        fadeinCount++;

      }
    }

    // console.log(tmpItems);


  }
}

export default EventFilter;
