/*globals $ */

class InviewObject {
  constructor($el) {
    this.$el      = $el;
    this.inView   = false;
    this.options  = {
      'offset': 0
    };

    if(this.$el.attr('data-inview') != undefined) {
      this.options = Object.assign(this.options, JSON.parse(this.$el.attr('data-inview')));
    }
  }

  update(scrollTop) {
    if(!this.inView) {
      if(scrollTop + this.windowHeight > this.$el.offset()['top'] + this.options['offset']) {
        this.inView = true;
        this.$el.addClass('InView--show');
      }
    }
  }

  onWindowResize(windowHeight) {
    this.windowHeight = windowHeight;
  }
}

class ScrollFx {
  constructor(mainApp) {
    this.mainApp = mainApp;
    this.$items = [];

    $('.InView').each((i, e) => {
      this.$items.push(new InviewObject($(e)));
    });
  }

  onWindowScroll(scrollTop) {
    for(let i = 0; i < this.$items.length; i++) {
      this.$items[i].update(scrollTop);
    }
  }

  onWindowResize() {
    for(let i = 0; i < this.$items.length; i++) {
      this.$items[i].onWindowResize(this.mainApp.windowHeight);
    }
  }
}

export default ScrollFx;
