
// import "core-js/modules/es.array";

import jQuery from 'jquery';
const $ = jQuery;

import 'cssuseragent';
import 'jquery.easing';

import 'slick-carousel';

import SlickSlider from './module/SlickSlider';
// import GoogleMap from './module/GoogleMap';
import ImgLazyLoad from './module/img-lazy-load';
import EventFilter from './module/EventFilter';
import ScrollFx from './module/ScrollFx';

window.jQuery = $;
window.$ = $;

// import MenuListItem from './MenuListItem';

if (!Object.assign) {
  Object.defineProperty(Object, 'assign', {
    enumerable: false,
    configurable: true,
    writable: true,
    value: function(target) {
      'use strict';
      if (target === undefined || target === null) {
        throw new TypeError('Cannot convert first argument to object');
      }

      var to = Object(target);
      for (var i = 1; i < arguments.length; i++) {
        var nextSource = arguments[i];
        if (nextSource === undefined || nextSource === null) {
          continue;
        }
        nextSource = Object(nextSource);

        var keysArray = Object.keys(Object(nextSource));
        for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
          var nextKey = keysArray[nextIndex];
          var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
          if (desc !== undefined && desc.enumerable) {
            to[nextKey] = nextSource[nextKey];
          }
        }
      }
      return to;
    }
  });
}

class Slideshow {
  constructor() {
    this.$container = $('.Slideshow');
    this.images = [];
    this.currentImg = 0;
    this.currentZ = 1;
    this.prevIndex = 0;

    this.$container.find('.SlideshowImg').each((i, e) => {
      this.images.push($(e));
    });
  }
  start() {
    this.images[0].addClass('-fadein');
    this.nextInterval = setInterval(() => {
      clearInterval(this.nextInterval);
      this.next();
    }, 6000);
  }

  next() {
    // console.log('next');
    this.fadeoutIndex = this.prevIndex;
    this.currentImg++;
    if(this.currentImg >= this.images.length) {
      this.currentImg = 0;
    }
    this.currentZ++;
    let nextIndex = this.currentImg % this.images.length;


    for(let i = 0; i < this.images.length; i++) {
      if(i == nextIndex) {
        this.images[i].css({'z-index': this.currentZ});
        this.images[i].addClass('-fadein');
        this.prevIndex = i;
      } else {
        if(i == this.fadeoutIndex) {
          setTimeout(() => {
            this.images[i].removeClass('-fadein');
          }, 1000);
        }
      }
    }

    this.nextInterval = setInterval(() => {
      clearInterval(this.nextInterval);
      this.next();
    }, 6000);
  }
}

class FxTxtItem {
  constructor($el) {
    this.$el = $el;
    this.$origin = this.$el.find('.FxTxtItem__origin');
    this.$over = this.$el.find('.FxTxtItemOver');
    this.$over.css('height', this.$origin.height());
    this.$color = this.$over.find('span');
  }

  fadein(delayTime) {
    this.$color
      .delay(delayTime)
      .animate({'width':'100%'}, 400, 'easeInExpo', () => {
        this.$origin.css('opacity', 1);
        this.$color.animate({'left':'100%', 'width':0}, 600, 'easeOutExpo');
      });
  }
}

class FxTxt {
  constructor($container) {
    this.$container = $container;
    this.items = [];
    this.$container.find('.FxTxtItem').each((i, e) => {
      this.items.push(new FxTxtItem($(e)));
    });
  }

  fadein(index) {
    for(let i = 0; i < this.items.length; i++) {
      this.items[i].fadein(index * 100 + i * 160);
    }
  }
}

class MainApp {
  constructor() {
    this.$window = $(window);

    if($('.Slideshow').length) {
      this.slideshow = new Slideshow();
    }

    this.viewMode = '';
    // this.prevScrollTop = 0;//scrollTop;
    // this.isShowSpHeader = true;
    this.isOpenSpMenu = false;
    this.isOpenSpLang = false;
    this.isSettingSpHeight = false;

    this.$menuItems = [];
    this.currentMenuIndex = -1;
    this.prevMenuIndex = -1;
    this.isHome = false;
    this.isShowHeaderBg = false;

    this.scrollFx         = new ScrollFx(this);

    if($('body').hasClass('home')) {
      this.isHome = true;
    }

    if($('body').hasClass('single')) {
      $('.SingleBody').find('iframe').each((i, e) => {
        if($(e).attr('src').indexOf('youtube') != -1) {
          let iframe = $(e);
          let ytContainer = $('<span class="embed-container"></span>');
          $(e).replaceWith(ytContainer)
          ytContainer.append(iframe);
        }
      });
    }

    this.imgLazyLoad = new ImgLazyLoad(this);

    /*
    $('.MenuListItem').each((i, e) => {
      this.$menuItems.push($(e));
    });

    if($('html').attr('lang') == 'ja') {
      this.$sections = [
        $('#section-event'),
        $('#section-campaign'),
        $('#section-space'),
        $('#section-access'),
        $('#section-contact')
      ];
    } else {
      this.$sections = [
        $('#section-event'),
        $('#section-space'),
        $('#section-access'),
        $('#section-contact')
      ];
    }
    */

    if($('.slick-slider').length) {
      new SlickSlider(this);
    }


    $('.wpcf7-acceptance').find('label').each((i, e) => {
      if(!$(e).hasClass('FoemLabel')) {
        $(e).addClass('FormLabel');
      }
    });


    $('.SpLangSwitcher').on('click', () => {
      if(this.isOpenSpLang) {
        this.isOpenSpLang = false;
        $('.SpLangSwitcherCollapse').css('height', 0);
      } else {
        this.isOpenSpLang = true;
        $('.SpLangSwitcherCollapse').css('height', 100);
      }
    });

    $('.js-toggle-sp-menu').on('click', () => {
      this.toggleSpMenu();
    });



    // トップへもどる
    $('.js-back-to-top').on('click', () => {
      $('html, body').animate({'scrollTop': 0}, 1200, 'easeInOutExpo', () => {
      });
    });

    $('a').on('click', (e) => {
      let href = $(e.currentTarget).attr('href');
      if(href.slice(0, 1) == '#') {

        if($(href).length) {
          // 要素が存在する場合

          let target = $(href).offset()['top'];

          if(this.viewMode == 'pc' && target > $('.Footer').offset()['top'] - $(window).height()) {
            target = $('.Footer').offset()['top'] - $(window).height();
          }

          if($(e.currentTarget).hasClass('SpMenuListItem__btn')) {
            this.closeSpMenu();
            setTimeout(() => {
              target = $(href).offset()['top'];
              $('html, body').animate({'scrollTop': target}, 1200, 'easeInOutExpo');
            }, 300);
          } else {
            $('html, body').animate({'scrollTop': target}, 1200, 'easeInOutExpo');
          }


        } else {
          // 要素が存在しない場合
          // $('html').animate({'scrollTop': 0}, 1200, 'easeInOutExpo');

          if($(e.currentTarget).hasClass('SpMenuListItem__btn')) {
            this.closeSpMenu();
            setTimeout(() => {
              $('html, body').animate({'scrollTop': 0}, 1200, 'easeInOutExpo');
            }, 300);
          } else {
            $('html, body').animate({'scrollTop': 0}, 1200, 'easeInOutExpo');
          }
        }


        return false;
      }
      // console.log($(e.currentTarget).attr('href'));
    });

    if($('body').hasClass('post-type-archive-event')) {
      new EventFilter();
    }

    if($('body').hasClass('page-template-page-contact')) {
      $('.FormPrivacyPolicy__inner').html($('.PrivacyPolicyContent')[0]);

      $('.wpcf7-submit').on('click', () => {
        $('html, body').animate({'scrollTop': $('.WrapContactContent').offset()['top']}, 1000, 'easeInOutExpo');
      });

      $('.wpcf7-back').on('click', () => {
        $('.wpcf7-form').removeClass('custom-wpcf7c-confirmed');
      });
    }

    this.$window
      .on('resize', ()=>{
        this.onWindowResize();
      })
      .on('scroll', ()=>{
        this.onWindowScroll();
      });
    this.onWindowResize();

    /*
    if($('#AccessMapEnbed').length) {
      new GoogleMap(this);
    }
    */

    let waitTime = 500;
    if(!$('body').hasClass('home')) {
      waitTime = 0;
    }

    setTimeout(() => {
      this.fadein();
    }, waitTime);
  }

  fadein() {
    if(this.slideshow) {
      this.slideshow.start();
    }

    setTimeout(() => {
      $('.Eyecatch__logo').addClass('-fadein');
      $('.Eyecatch__txt').addClass('-fadein');
    }, 1000);

    this.fxTxt = [];

    if($('.CommonHeader').length) {
      $('.CommonHeader').addClass('CommonHeader--fadein');
    }

    $('.FxTxt').each((i, e) => {
      this.fxTxt.push(new FxTxt($(e)));
    });

    let waitTime = 2500;
    if(!$('body').hasClass('home')) {
      waitTime = 800;
    }

    setTimeout(() => {
      for(let i = 0; i < this.fxTxt.length; i++) {
        this.fxTxt[i].fadein(i);
      }

      $('.Lead__small').css('opacity', 1);
      $('.HeroScrolldown').addClass('HeroScrolldown--fadein');
      $('.Header').addClass('Header--fadein');
    }, waitTime);
  }


  toggleSpMenu() {
    if(this.isOpenSpMenu) {
      this.closeSpMenu();
    } else {
      this.openSpMenu();
    }

    /*
    if(this.isOpenSpMenu) {
      this.isOpenSpMenu = false;
      $('body').removeClass('open-sp-menu');
      $('html, body').scrollTop(this.prevScrollTop);
    } else {
      this.isOpenSpMenu = true;
      $('body').addClass('open-sp-menu');
    }
    */
  }

  openSpMenu() {
    this.isOpenSpMenu = true;
    $('main').css('display', 'none');
    $('.Footer').css('display', 'none');

    $('body').addClass('open-menu-sp');
    $('.BtnToggleSpMenuIcon').addClass('BtnToggleSpMenuIcon--close');
    $('.SpHeader').addClass('SpHeader--open-menu');
    $('.SpHeaderScroller').stop(false).css({'display':'block', 'opacity':1});//.animate({'opacity':});
  }

  closeSpMenu() {
    this.isOpenSpMenu = false;
    $('main').css('display', 'block');
    $('.Footer').css('display', 'block');

    $('body').removeClass('open-menu-sp');
    $('.BtnToggleSpMenuIcon').removeClass('BtnToggleSpMenuIcon--close');

    $('html, body').scrollTop(this.prevScrollTop);
    $('.SpHeaderScroller').stop(false).animate({'opacity':0}, 400, 'easeOutExpo', () => {
      $('.SpHeaderScroller').css('display', 'none');
      $('.SpHeader').removeClass('SpHeader--open-menu');
    });//css({'display':'block', 'opacity':1});
  }

  setMenuPosition(target) {
    if(this.prevMenuIndex != target) {
      this.currentMenuIndex = target;

      if(target == -1) {
        for(let i = 0; i < this.$menuItems.length; i++) {
          this.$menuItems[i].removeClass('MenuListItem--current');
        }
      } else {
        for(let i = 0; i < this.$menuItems.length; i++) {
          if(i == target) {
            this.$menuItems[i].addClass('MenuListItem--current');
          } else {
            this.$menuItems[i].removeClass('MenuListItem--current');
          }
        }
      }
    }
    this.prevMenuIndex = target;
  }

  onWindowScroll() {
    this.scrollTop = this.$window.scrollTop();

    if(this.scrollTop > 200) {
      $('.HeroScrolldown').addClass('HeroScrolldown--fadeout');
    } else {
      $('.HeroScrolldown').removeClass('HeroScrolldown--fadeout');
    }

    // sconsole.log(this.scrollTop);
    // if(this.viewMode == 'pc' && this.isHome) {
    /*
    if(this.isHome) {
      if(this.scrollTop > this.windowHeight - 60) {
        $('.Header').addClass('Header--invert');
        $('.SpHeader').addClass('SpHeader--invert');
      } else {
        $('.Header').removeClass('Header--invert');
        $('.SpHeader').removeClass('SpHeader--invert');
      }
    }
    */

    if(this.isHome) {
      if(this.viewMode == 'pc') {
        if(this.scrollTop > this.windowHeight - 60) {
          $('.Header').addClass('Header--invert');
          // $('.SpHeader').addClass('SpHeader--invert');
        } else {
          $('.Header').removeClass('Header--invert');
          // $('.SpHeader').removeClass('SpHeader--invert');
        }

      } else {
        if(this.scrollTop > this.windowHeight - 60) {
          $('.SpHeader').addClass('SpHeader--has-bg');
          // $('.SpHeader').addClass('SpHeader--invert');
        } else {
          $('.SpHeader').removeClass('SpHeader--has-bg');
          // $('.SpHeader').removeClass('SpHeader--invert');
        }
      }
    }

    this.scrollFx.onWindowScroll(this.scrollTop);



    if(this.isHome) {
      if(this.scrollTop < 10) {
        if(this.isShowHeaderBg) {
          this.isShowHeaderBg = false;
          $('.Header').removeClass('Header--has-bg');
          // $('SpHeader').removeClass('SpHeader--has-bg');
        }
      } else {
        if(!this.isShowHeaderBg) {
          this.isShowHeaderBg = true;
          $('.Header').addClass('Header--has-bg');
          // $('SpHeader').addClass('SpHeader--has-bg');
        }
      }
    }


    this.imgLazyLoad.update(this.scrollTop, this.windowHeight);

    /*
    let tmpIndex = -1;
    for(let i = 0; i < this.$menuItems.length; i++) {
      if(this.scrollTop >= this.$sections[(this.$menuItems.length - 1) - i].offset()['top'] - 150) {
        tmpIndex = (this.$menuItems.length - 1) - i;
        break;
      }
    }
    this.setMenuPosition(tmpIndex);
    */

    if(!this.isOpenSpMenu) {
      this.prevScrollTop = this.scrollTop;
    }
  }

  onWindowResize() {
    this.windowWidth = this.$window.width();
    this.windowHeight = this.$window.height();

    if($('.check-view').width() == 1200) {
      this.viewMode = 'pc';
    } else if($('.check-view').width() == 1000) {
      this.viewMode = 'tablet';
    } else if($('.check-view').width() == 750) {
      this.viewMode = 'sp';
    }

    if(this.viewMode == 'pc') {
      $('.Hero').css('height', '100vh');
    } else {
      if(!this.isSettingSpHeight) {
        this.isSettingSpHeight = true;
        $('.Hero').css('height', window.innerHeight);
      }
    }

    if($('.SlideBtn').length) {
      if($('.SlideBtn').parent().hasClass('EventSlideSlider')) {
        $('.SlideBtn').css('top', Math.floor($('.SlideBtn').parent().width() / 1.777));
      } else {
        $('.SlideBtn').css('top', Math.floor($('.SlideBtn').parent().width() / 1.777 / 2));
      }


    }

    this.scrollFx.onWindowResize();

    this.onWindowScroll();
  }
}

$(document).ready(() => {


  // $('body').addClass('time-day');
  /*
  $('body').on('click', () => {
    if($('body').hasClass('time-day')) {
      $('body').removeClass('time-day');
      $('body').addClass('time-night');
    } else if($('body').hasClass('time-night')) {
      $('body').removeClass('time-night');
      $('body').addClass('time-day');
    }
  });
  */

  new MainApp();
});


$(window).on('load', () => {

  if($('.DelayFadeinSection').length) {
    setTimeout(() => {
      $('.DelayFadeinSection').css('opacity', 1);
    }, 1000);

  }

});
